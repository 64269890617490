import React from "react"
import SeoHead from "../../components/global/seoHead"

import Layout from "../../components/global/layout"
import Seo from "../../components/global/seo"
import ScrollIndicationHeightZero from "../../components/global/scrollIndicationHeightZero"


import brandingFeaturedImage from "../../../static/branding/xhale/xhale-featured-image.png"

import "../../sass/pages/case-studies/cs-global.scss"
import "../../sass/pages/branding/xhale.scss"
import XhaleMain from "../../components/branding/xhale/xhaleMain"
import XhaleFullwidth from "../../components/branding/xhale/xhaleFullWidth"
import XhaleMoreBranding from "../../components/branding/xhale/xhaleMoreBranding"


export const Head = () => (
    <>
        <SeoHead 
            ogImage={brandingFeaturedImage}
            title="Xhale: Breathing Life into Fitness Branding | WowMakers"
            description="See how WowMakers energized Xhale's brand identity. Our design motivates and inspires, just like their fitness ethos."
        />
    </>
)

const DharaAyurEssentialsBranding = () => {
    return (
        <Layout>
            <Seo
                bid="branding-xhale"
                bclass="case-studies-page branding"
                >
            </Seo>
            <ScrollIndicationHeightZero />
            <XhaleMain />
            <XhaleFullwidth />
            <XhaleMoreBranding />
        </Layout>
    )
}

export default DharaAyurEssentialsBranding